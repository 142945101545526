<template>
  <div v-if="!kosten && !kostenInfo">Loading...
  </div>
  <div v-else>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="2">
            <div>
              Honorar: {{ kostenInfo === null ? 0 : kostenInfo.Honorar }}
            </div>
          </b-col>
          <b-col md="2">
            <div>
              Verrechnet: {{ kostenInfo === null ? 0 : kostenInfo.Verrechnet }}
            </div>
          </b-col>
          <b-col md="2">
            <div>
              Saldo: {{ kostenInfo === null ? 0 : kostenInfo.Saldo }}
            </div>
          </b-col>
          <b-col md="2">
            <div>
              Offen: {{ kostenInfo === null ? 0 : kostenInfo.Offen }}
            </div>
          </b-col>
          <b-col>
            <div class="mb-1 text-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="addRechnung()"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  class="mr-1"
                />
                <span class="align-middle">Rechnung erstellen</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </b-card-body>

      <b-table
        ref="selectableTable"
        selectable
        select-mode="single"
        striped
        hover
        responsive
        class="position-relative"
        :items="items"
        primary-key="ProtID"
        :fields="fields"
        @row-selected="showEintrag"
      >
        <template #cell(Typ)="data">
          <div>
            {{ tabTyp[data.value] }}
          </div>
        </template>
        <template #cell(Status)="data">
          <b-badge :variant="tabStatus[1][data.value]">
            {{ tabStatus[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(Betrag)="data">
          <div class="text-right">
            {{ data.value }}
          </div>
        </template>
        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Honorartyp"
                  label-for="typ"
                >
                  <v-select
                    v-model="row.item.Typ"
                    :options="honorarTyp"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <v-select
                    v-model="row.item.Status"
                    :options="status"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Rechnung an"
                  label-for="empfaenger"
                >
                  <v-select
                    v-model="row.item.EltID"
                    :options="empfaenger"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  label="Betreff"
                  label-for="betreff"
                >
                  <b-form-input
                    v-model="row.item.Betreff"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="4"
                class="text-center"
              >
                <b-form-group
                  label="Mit Protokoll"
                  label-for="protokoll"
                >
                  <b-form-checkbox
                    v-model="row.item.MitProtokoll"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  label="Bezeichnung Betrag"
                  label-for="BetragText"
                >
                  <b-form-input
                    v-model="row.item.BetragText"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Betrag"
                  label-for="betrag"
                >
                  <b-form-input
                    v-model="row.item.Betrag"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Bemerkung (nur interne Verwendung, erscheint nicht auf Honorarrechnung)"
                  label-for="bemerkung"
                >
                  <b-form-textarea
                    v-model="row.item.Bemerkung"
                    rows="2"
                    max-rows="4"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3 text-center mt-2">
                <b-button
                  variant="primary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="speichern(row.item)"
                >
                  Speichern
                </b-button>
              </b-col>
              <b-col md="3 text-center mt-2">
                <b-button
                  variant="primary"
                  :disabled="!(currRow.HonorarID !== 0 && (currRow.Status === 'erstellt' || currRow.Status === 'keinhonorar'))"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="create"
                >
                  Erstellen
                </b-button>
              </b-col>
              <b-col md="3 text-center mt-2">
                <b-button
                  variant="primary"
                  :disabled="currRow.HonorarID === 0"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="download"
                >
                  Herunterladen
                </b-button>
              </b-col>
              <b-col md="3 text-center mt-2">
                <b-button
                  variant="primary"
                  :disabled="!(currRow.Status === 'erstellt')"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="remove"
                >
                  Löschen
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import dos from '@/service/dossier'
import db from '@/service/honorar'
import dbp from '@/service/protokoll'

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BBadge,
  },
  directives: {
    Ripple,
  },
  setup() {
    const tabTyp = {
      anzahlung: 'Anzahlung', zwischenzahlung: 'Zwischenzahlung', schlusszahlung: 'Schlusszahlung',
    }
    const honorarTyp = [
      { label: 'Anzahlung', value: 'anzahlung' },
      { label: 'Zwischenzahlung', value: 'zwischenzahlung' },
      { label: 'Schlusszahlung', value: 'schlusszahlung' },
    ]
    const tabStatus = [{
      keinhonorar: 'Kein Honorar', erstellt: 'Erstellt', versand: 'Versandt', bezahlt: 'Bezahlt', erinnert: 'Zahlungserinnerung', gemahnt: 'Mahnung',
    },
    {
      keinhonorar: '', erstellt: '', versand: '', bezahlt: 'light-success', erinnert: 'light-warning', gemahnt: 'light-warning',
    }]
    const status = [
      { label: 'Kein Honorar erstellt', value: 'keinhonorar' },
      { label: 'Erstellt', value: 'erstellt' },
      { label: 'Versandt', value: 'versand' },
      { label: 'Bezahlt', value: 'bezahlt' },
      { label: 'Erinnert', value: 'erinnert' },
      { label: 'Gemahnt', value: 'gemahnt' },
    ]
    return {
      tabTyp,
      honorarTyp,
      tabStatus,
      status,
    }
  },
  data() {
    return {
      empfaenger: {},
      totalRows: 1000,
      fields: [
        // { key: 'show_details', label: 'Details' },
        { key: 'Erstellt', label: 'Datum' },
        { key: 'Typ', label: 'Honorartyp' },
        { key: 'Status', label: 'Status' },
        { key: 'Betrag', label: 'Betrag', thClass: 'text-right' },
      ],
      kosten: null,
      kostenInfo: null,
      items: null,
      currRow: null,
      selected: [],
    }
  },
  async created() {
    this.fetch(true, 0)
  },
  methods: {
    async fetch(mitDosKosten, honorarID) { // True on 1st load...
      const k = await db.getHonorarKosten(this.$root.currDosID)
      if (k.data !== null && k.data.length === 1) this.kostenInfo = k.data[0]

      if (mitDosKosten) {
        const r = await dos.getDossierKosten(this.$root.currDosID, this.$g.user.BenSprache)
        if (r.data.length > 0) {
          this.kosten = r.data[0]
          this.empfaenger = [
            { label: this.kosten.VornameF, value: this.kosten.EltIDF },
            { label: this.kosten.VornameM, value: this.kosten.EltIDM },
          ]
        }
      }

      let h = await db.getHonorar(0, this.$root.currDosID)
      if (h.data.length === 0) {
        h = await db.getHonorar(1, 0)
        h.data[0].Erstellt = new Date().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
        h.data[0].HonorarID = 0
        h.data[0].DosID = this.$root.currDosID
        h.data[0].EltID = this.kosten.RechnungKlient ? this.kosten.EltIDF : this.kosten.EltIDM
      }

      if (h.data.length > 0) { // eslint-disable-line
        for (let i = 0; i < h.data.length; i += 1) h.data[i]._showDetails = false // eslint-disable-line
        this.items = h.data
        this.totalRows = this.items.length
      }

      for (let x = 0; x < this.items.length; x += 1) {
        if (this.items[x].HonorarID === honorarID) {
          this.currRow = this.items[x]
          this.currRow._showDetails = true // eslint-disable-line
        }
      }
    },
    async addRechnung() {
      for (let i = 0; i < this.items.length; i += 1) this.$refs.selectableTable.unselectRow(i)
      const np = JSON.parse(JSON.stringify(this.items)).slice(0, 1)
      np[0].Erstellt = new Date().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
      np[0].HonorarID = 0
      np[0].DosID = this.$root.currDosID
      np[0].EltID = this.kosten.RechnungKlient ? this.kosten.EltIDF : this.kosten.EltIDM
      np[0].Typ = this.items.length === 1 ? 'zwischenzahlung' : 'schlusszahlung'
      np[0].Status = 'keinhonorar'
      np[0].ProtokollHonorar = 0
      np[0].Betreff = this.items.length === 1 ? 'Zwischenzahlung' : 'Schlusszahlung'
      np[0].BetragText = this.items.length === 1 ? 'Zwischenzahlung' : 'Schlusszahlung'
      np[0].Betrag = this.kostenInfo.Offen
      np[0].MitProtokoll = false
      np[0].Bemerkung = ''
      np[0]._showDetails = true // eslint-disable-line
      this.items.push(np[0])

      this.currRow = this.items[this.items.length - 1]
      this.totalRows = this.items.length
      const k = await db.getHonorarKosten(this.$root.currDosID)
      if (k.data.length === 1) this.kostenInfo = k.data[0]
    },
    showEintrag(items) {
      if (items.length > 0) this.currRow = items[0]
      this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
    },
    async speichern(data) {
      const r = await db.insUpdHonorar(data) // eslint-disable-line
      if (data.HonorarID === 0 && r.data.length > 0) {
        const h = await db.getHonorar(0, this.$root.currDosID)
        this.items = h.data
        this.totalRows = this.items.length
        for (let i = 0; i < this.items.length - 1; i += 1) {
          this.$refs.selectableTable.unselectRow(i)
          this.items[i]._showDetails = false // eslint-disable-line
        }
        this.$refs.selectableTable.selectRow(this.items.length - 1)
        this.currRow = this.items[this.items.length - 1]
        this.items[this.items.length - 1]._showDetails = true // eslint-disable-line
      }

      this.fetch(false, data.HonorarID)

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Änderungen gespeichert',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Honorardaten wurden erfolgreich gespeichert!',
          },
        },
        { position: 'bottom-right' },
      )
    },
    async create() {
      const r = await db.createHonorar(this.currRow.HonorarID) // eslint-disable-line
      const p = await dbp.getProtokollUpdateHonorar(this.$root.currDosID, this.currRow.HonorarID) // eslint-disable-line
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Honorarrechnung erstellen',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Honorarrechnung wurde erfolgreich erstellt!',
          },
        },
        { position: 'bottom-right' },
      )
    },
    async download() {
      const r = await db.getHonorarDatei(this.currRow.HonorarID)
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'Honorar.pdf')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async remove() {
      const r = await db.deleteHonorar(this.currRow.HonorarID) // eslint-disable-line
      this.fetch(false, 0)

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Honorarrechnung gelöscht',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Honorarrechnung wurde erfolgreich gelöscht!',
          },
        },
        { position: 'bottom-right' },
      )
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
