import axios from '@axios'

export default {
  async getProtokoll(dosID) {
    try {
      const r = await axios.get('sproc/getProtokoll', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putProtokoll(data) {
    try {
      const r = await axios.get('sproc/getProtokollUpdate', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async newProtokoll(data) {
    try {
      const r = await axios.get('sproc/getProtokollInsert', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getProtokollUpdateHonorar(dosID, honorarID) {
    try {
      const r = await axios.get('sproc/getProtokollUpdateHonorar', {
        params: {
          dosID,
          honorarID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
